import { useContext, type ReactNode } from 'react';
import { Modal } from 'antd';

import { i18nContext } from 'context/i18n';
import { CloseModalSVG } from 'components/iconsSVG/close';
import styles from './style.module.css';
import { Button } from 'components/ui/button';
import { Dialog, DialogRoot, DialogX } from 'components/ui/dialog';
import { DialogTrigger } from 'react-aria-components';

export function ProtectionDataModal({ visible, onCancel, origin = 'checkout' }: { visible: boolean; onCancel: () => void; origin: string }) {
  const { t, profileData } = useContext(i18nContext);

  return (
    // @ts-ignore !antd
    <Modal width={'400px'} visible={visible} footer={null} onCancel={onCancel} closeIcon={<CloseModalSVG />} className={styles.protectionData}>
      <h2>{t('menu.derecha', 'informacion.proteccion.datos')}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: t('menu.derecha', `proteccion.datos.info.${origin}`).replace('<TAG privacy_policy_url>', profileData?.urlPrivacyPolicy),
        }}
      />
    </Modal>
  );
}

export function LabProtectionDataModal({ trigger, origin = 'checkout' }: { trigger: ReactNode; origin: string }) {
  const { t, profileData } = useContext(i18nContext);

  return (
    <DialogTrigger>
      {trigger}
      <DialogRoot isDismissable>
        <Dialog>
          <div>
            {t('menu.derecha', 'informacion.proteccion.datos')}
            <DialogX />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: t('menu.derecha', `proteccion.datos.info.${origin}`).replace('<TAG privacy_policy_url>', profileData?.urlPrivacyPolicy),
            }}
          />
        </Dialog>
      </DialogRoot>
    </DialogTrigger>
    //   <h2>{t('menu.derecha', 'informacion.proteccion.datos')}</h2>
    // </Modal>
  );
}
// ProtectionDataModal.propTypes = {
//   visible: bool,
//   onCancel: func,
//   origin: string,
// };

export default ProtectionDataModal;
