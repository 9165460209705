import { useContext } from 'react';
import { string, object, bool } from 'prop-types';
import { useUser } from 'context/user';

import { i18nContext } from 'context/i18n';
import { getIsMobile, capitalize, getLang, getMarket, getRealLanguage, getProductsUnbxdFormatted, obtainVoucherPrices } from 'utils/helpers';

const DOCTYPES = {
  TOP_QUERIES: 'TOP_SEARCH_QUERIES',
  KEYWORD: 'KEYWORD_SUGGESTION',
  PROMOTED: 'PROMOTED_SUGGESTION',
};
const DOCTYPES_LIST = [DOCTYPES.TOP_QUERIES, DOCTYPES.KEYWORD, DOCTYPES.PROMOTED];
const DOCTYPE_SCORE = {
  TOP_SEARCH_QUERIES: 2,
  KEYWORD_SUGGESTION: 1,
  PROMOTED_SUGGESTION: 3,
};

const AutoSuggest = ({ query, data, visible, setVisible }) => {
  const { t, locale, unbxdInitialSearch, profileData } = useContext(i18nContext);
  const { contextFilters, activatedPrevouchers, handleActivatePrevouchers } = useUser();
  const isMobile = getIsMobile('768px');
  const isTablet = getIsMobile('1200px');
  const lang = getLang(locale);
  const market = getMarket(locale);

  if ([undefined, null].includes(data) || visible === false) {
    return null;
  }

  const isSearchAll = ['', '*'].includes(query);
  const dataToUse = isSearchAll ? unbxdInitialSearch : data;
  const topQueries = dataToUse?.products?.filter((item) => DOCTYPES_LIST.includes(item.doctype)).map((item) => item.autosuggest);

  topQueries?.sort((a, b) => {
    const aScore = DOCTYPE_SCORE[a.doctype];
    const bScore = DOCTYPE_SCORE[b.doctype];
    let result = bScore - aScore;

    if (result < -1) result = -1;
    if (result > 1) result = 1;

    return result;
  });

  const products = dataToUse?.products?.filter((item) => item.doctype === 'POPULAR_PRODUCTS');
  const transformRegex = isSearchAll ? null : new RegExp(query, 'g');
  const transformRegexUpperCase = isSearchAll ? null : new RegExp(query.toUpperCase(), 'g');
  const transformRegexCapitalize = isSearchAll ? null : new RegExp(capitalize(query), 'g');
  const isoLang = `${getRealLanguage(lang)}-${market}`;
  const realMarketLang = `${market}_${getRealLanguage(lang)}`;
  const formattedProducts = getProductsUnbxdFormatted(products, locale);

  const handleProductsScroll = () => {
    document.activeElement.blur();
  };

  let wrapperClass = 'unbxd-autosuggest-';

  if (isMobile) {
    wrapperClass += 'mobile';
  } else if (isTablet) {
    wrapperClass += 'tablet';
  } else {
    wrapperClass += 'desktop';
  }

  return (
    <div className={wrapperClass}>
      <div
        id="autosuggest-widget"
        className={`unbxd-as-wrapper ${isMobile ? 'unbxd-as-mobile' : 'unbxd-as-desktop'} unbxd-as-extra-right fixed left-0 top-[99px] z-50 flex !h-[70vh] !flex-col !overflow-y-hidden bg-[var(--color-white)] p-[16px] pt-[19px] shadow-md md:absolute md:top-[41px] md:!h-[614px] md:w-[700px] md:!flex-row-reverse tablet:left-auto tablet:right-0 tablet:top-[37px]`}
      >
        {dataToUse !== false ?
          <>
            <ul onScroll={handleProductsScroll} class={`${isMobile ? 'unbxd-as-maincontent' : 'unbxd-as-sidecontent'} overflow-y-auto`}>
              <li class="unbxd-as-header flex items-center justify-between">{t('buscador', 'unbxd.best.results')}</li>
              {products.map((item, index) => {
                const product = { ...item };
                const voucherPriceInfo = obtainVoucherPrices(
                  formattedProducts[index],
                  t,
                  profileData,
                  contextFilters,
                  activatedPrevouchers,
                  handleActivatePrevouchers,
                  false,
                  false,
                  'price.message.short',
                );

                let voucherMessage = null;

                if (voucherPriceInfo !== null) {
                  if (voucherPriceInfo.message) {
                    ({ message: voucherMessage } = voucherPriceInfo);
                  } else {
                    product[`${market}_max_sale_price_unx_d`] = parseFloat(voucherPriceInfo?.info?.current);
                    product[`${market}_max_price_unx_d`] = voucherPriceInfo?.info?.previous;
                    product[`${market}_sale_percentage`] = voucherPriceInfo?.info?.discount;
                  }
                }

                return (
                  <li
                    key={`unbxd-product-${index}`}
                    className={`unbxd-as-popular-product unbxd-as-popular-product-grid !float-none md:hover:!bg-[#f6f6f6]`}
                    data-value={product.autosuggest}
                    data-src=""
                    data-index={index}
                    data-type={product.doctype}
                    data-pid={product.uniqueId}
                  >
                    <a class="unbxd-as-popular-product-info" href={product[`${realMarketLang}_productUrl`]}>
                      <div class="unbxd-as-popular-product-image-container unbxd-oss-img">
                        <img src={product.imageUrl[0]} class="unbxd-oss-image" alt="Product Image" />
                      </div>
                      <div class="unbxd-oss-name">{product.title}</div>
                      <div class="unbxd-oss-supporttext">{product[`${lang}_product_sheet_h1`]}</div>
                      <div class="unbxd-as-popular-product-price">
                        {product[`${market}_max_sale_price_unx_d`] ?
                          <>
                            <div class="unbxd-sale-price">
                              {product[`${market}_max_sale_price_unx_d`]?.toLocaleString(isoLang, {
                                style: 'currency',
                                currency: product[`${market}_currency_unx_ts`],
                                maximumFractionDigits: 0,
                              })}
                            </div>
                            <div>
                              <span class="unbxd-was-price">
                                {product[`${market}_max_price_unx_d`]?.toLocaleString(isoLang, {
                                  style: 'currency',
                                  currency: product[`${market}_currency_unx_ts`],
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              <span class="unbxd-discount"> -{product[`${market}_sale_percentage`]}%</span>
                            </div>
                          </>
                        : <div class="unbxd-sale-price">
                            {product[`${market}_max_price_unx_d`]?.toLocaleString(isoLang, {
                              style: 'currency',
                              currency: product[`${market}_currency_unx_ts`],
                              maximumFractionDigits: 0,
                            })}
                          </div>
                        }
                        {voucherMessage !== null && <span className="text-black text-[10px] font-medium">{voucherMessage}</span>}
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
            {!isMobile && (
              <ul class="unbxd-as-maincontent">
                <li class="unbxd-as-header main-section-header">{t('buscador', 'unbxd.suggestions')}</li>
                {topQueries.map((item, index) => {
                  const transformedQuery =
                    isSearchAll ? item : (
                      item
                        .replace(transformRegex, `<b>${query}</b>`)
                        .replace(transformRegexUpperCase, `<b>${query.toUpperCase()}</b>`)
                        .replace(transformRegexCapitalize, `<b>${capitalize(query)}</b>`)
                    );

                  return (
                    <li key={`top-query-${index}`} class="unbxd-as-topqueries" data-type="TOP_SEARCH_QUERIES" data-index={index} data-value={item}>
                      <span
                        onClick={() => {
                          window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${item}&target=A`);
                        }}
                        dangerouslySetInnerHTML={{ __html: transformedQuery }}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            <a
              href={`${window.location.origin}/${locale}/search?target=A&searchTerm=${query}`}
              className="bottom-[0px] left-[50%] flex w-[75%] items-center justify-center self-center border-[1px] border-[var(--color-black)] p-[10px] align-middle text-[14px] uppercase md:absolute md:bottom-[25px] md:ml-auto md:min-w-[155px] md:max-w-[200px] md:px-[15px] md:py-[10px] tablet:right-[25px]"
            >
              {t('buscador', 'unbxd.see.all')}
              <span className="ml-2">{'>'}</span>
            </a>
          </>
        : <span>
            {t('buscador', 'no.results.for')} {query}
          </span>
        }
      </div>
    </div>
  );
};

AutoSuggest.propTypes = {
  data: object,
  query: string,
  visible: bool,
};

AutoSuggest.defaultProps = {};

export default AutoSuggest;
